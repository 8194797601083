export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Members']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Members',
        route: '/base',
        icon: 'cil-people',
        items: [
          {
            name: 'Member List',
            to: '/base/cards'
          },
          {
            name: 'Add New Member',
            to: '/base/breadcrumbs'
          },
          {
            name: 'Online Members',
            to: '/base/breadcrumbs'
          }
        ]
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Reports']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Reports',
        route: '/base',
        icon: 'cil-list',
        items: [
          {
            name: 'Win/Lose',
            to: '/base/breadcrumbs'
          },
          {
            name: 'Transaction Log',
            to: '/base/cards'
          },
          {
            name: 'Credit Transfer (Provider)',
            to: '/base/cards'
          },
        ]
      }
    ]
  }
]