<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <span v-if="!minimize">super</span>
      <span v-if="minimize">s</span>
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="computedSidebar"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav0 from './_nav0'
import nav1 from './_nav1'

export default {
  name: 'TheSidebar',
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    },
    computedSidebar() {
      if(window.localStorage.getItem('isSuper') == 1){
        return nav1
      }else{
        return nav0
      }
    }
  }
}
</script>
