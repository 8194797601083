export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Members']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Members',
        route: '/base',
        icon: 'cil-people',
        items: [
          {
            name: ' - Member List',
            to: '/base/cards'
          },
          {
            name: ' - Add New Member',
            to: '/base/breadcrumbs'
          },
          {
            name: ' - Online Members',
            to: '/base/breadcrumbs'
          }
        ]
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Reports']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Reports',
        route: '/base',
        icon: 'cil-chart-line',
        items: [
          {
            name: ' - Win/Lose',
            to: '/base/breadcrumbs'
          },
          {
            name: ' - Transaction Log',
            to: '/transactions/list'
          },
          {
            name: ' - Cashapp Deposits',
            to: '/transactions/cashapp-deposits'
          },
          {
            name: ' - Credit Transfer (Provider)',
            to: '/transactions/credit-transfer-provider'
          },
        ]
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Administrative']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Users',
        route: '/base',
        icon: 'cil-contact',
        items: [
          {
            name: ' - User List',
            to: '/admins/list'
          },
          {
            name: ' - Add New User',
            to: '/admins/create'
          },
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Cashtags',
        route: '/base',
        icon: 'cil-dollar',
        items: [
          {
            name: ' - Cashtag List',
            to: '/cashtags/list'
          },
          {
            name: ' - Add New Cashtag',
            to: '/cashtags/create'
          },
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Games',
        route: '/games',
        icon: 'cil-gamepad',
        items: [
          {
            name: 'Game List',
            to: '/games/list'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Sites',
        route: '/sites',
        icon: 'cil-contact',
        items: [
          {
            name: ' - Site List',
            to: '/sites/list'
          },
          {
            name: ' - Add New Site',
            to: '/sites/create'
          },
        ]
      }
    ]
  }
]